
















import KCard from '@/@core/components/other/KCard.vue';
import {
  defineComponent,
  ref,
  computed,
  PropType,
  unref,
  watch
} from '@vue/composition-api';
import { BarChart, useBarChart } from 'vue-chart-3';

export default defineComponent({
  name: 'StatisticChart',
  components: {
    BarChart,
    KCard
  },
  props: {
    statistics: {
      type: Array as PropType<any[]>,
      required: true
    }
  },
  setup(props) {
    const dataSet = ref<any[]>([]);
    const labels = ref<string[]>([]);

    const getLabels = () => {
      const data = props?.statistics;
      if (!data) return;
      const length = props.statistics.length - 1;
      labels.value = props.statistics
        .slice(0, length)
        .map((s) => s.shortTitle);
    };

    const getDataSet = () => {
      const data = props?.statistics;
      if (!data) return;
      const length = props.statistics.length - 1;
      dataSet.value = props.statistics
        .slice(0, length)
        .map((s) => s.value);
    };
    const data = ref([30, 40, 60, 70, 5]);
    const legendTop = ref(true);
    const imgData = ref(null);

    const options = computed(() => ({
      scales: {
        y: {
          beginAtZero: true
        }
      },
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true
            },
            pinch: {
              enabled: true
            },
            mode: 'xy'
          }
        }
      }
    }));

    const chartData = computed(() => ({
      labels: unref(labels),
      datasets: [
        {
          data: unref(dataSet),
          backgroundColor: [
            '#77CEFF',
            '#0079AF',
            '#123E6B',
            '#97B0C4'
          ]
        }
      ]
    }));

    const { barChartProps, barChartRef }: any = useBarChart({
      chartData,
      options
    });

    function shuffle(array: any[]) {
      return array.sort(function () {
        return 0.5 - Math.random();
      });
    }

    function shuffleData() {
      dataSet.value = shuffle(dataSet.value);
      legendTop.value = !legendTop.value;
      imgData.value = barChartRef.value.chartInstance.toBase64Image();
      barChartRef.value.chartInstance.resetZoom();
    }

    function zoom() {
      barChartRef.value.chartInstance.zoom(1.01);
    }

    watch(
      () => props.statistics,
      (val) => {
        getDataSet();
        getLabels();
      },
      { immediate: true }
    );

    return { shuffleData, barChartProps, barChartRef, imgData, zoom };
  }
});
